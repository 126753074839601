@import url('./assets/scss/theme.scss');

img {
    width: 100%;
}

.modal-header {
    border-bottom: none;
}

.phone-input-container.react-tel-input .form-control {
    width: 100%;
    border: var(--ar-border-width) solid var(--ar-gray-400);
    border-radius: 16px;
    height: 48px;
}

.footer {
    font-size: 14px;
}

body {
    background: #ffffff;
}

.main-container {
    padding-top: 100px;
    padding-bottom: 100px;
    background-image: url('./assets/img/bg.png');
    background-repeat: no-repeat;
    background-size: cover;
}

.blue-block {
    background: url('./assets/img/lines.png'),
        #0B59BB;
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: 100%;
    padding: 60px;
    border-radius: 20px;

    h1,
    p {
        color: #ffffff;
    }

    h1 {
        font-size: 32px;
    }

    p {
        font-size: 20px;
    }
}

.dropdown-toggle {
    color: var(--ar-btn-active-color);
    background-color: var(--ar-btn-active-bg);
    border: none;
}

.dropdown-menu {
    padding: 10px 20px;
}

.bg-blue {
    background-color: #F4F5FA;
}

.flex {
    display: flex;
}

.flex-center {
    display: flex;
    align-items: center;
}

.flex-baseline {

    align-items: baseline;
}

.flex-space-between {
    justify-content: space-between;
}

.flex-row-center {
    justify-content: center;
}

.flex-stretch {
    align-items: stretch;
}

.logo {
    color: #0E519C;
    text-decoration: none;
    font-weight: 800;
    font-size: 30px;
}

.btn-secondary {
    border: 1.5px solid #E5E5E7;
    background: #ffffff;
}

.ui-card {
    height: 100px;
    background: #FFFFFF;
    box-shadow: 0px 7px 20px -2px rgba(208, 208, 196, 0.32);
    border-radius: 18px;
    display: flex;
    align-items: center;
    margin-bottom: 25px;

    img {
        margin-right: 24px;
    }
}

.offers-row {
    display: flex;
}

.price {
    color: #0071FF;
    font-size: 48px;
    font-weight: 700;
}

.price-btn {
    max-width: 300px;
    width: 100%;
    position: absolute;
    bottom: 60px;
    left: 50%;
    transform: translateX(-50%);
}

.price-btn-reverse {
    background: #E5E5E7;
    color: #0071FF;
    border: none;
}

.btn {
    font-size: 20px;
    font-weight: 400;
    border-radius: 10px;
}

.btn-close {
    background: transparent url("./assets/img/close.svg") center/1.375em auto no-repeat
}

.ui-modal {
    h3 {
        font-weight: 400;
    }
}

.modal-content {
    padding: 40px;
}

.user-avatar {
    text-align: center;
    line-height: 80px;
    width: 80px;
    height: 80px;
    background-color: #ffffff;
    border-radius: 40px;
}

.selected-price {

    background-color: #0071FF !important;
    color: #ffffff;

    h3,
    .price {
        color: #ffffff;
    }

    .price-btn {
        background: #FFFFFF;
        color: #121519;
    }

}

.method-card {
    cursor: pointer;
    width: 90px;
    text-align: center;
    border-radius: 4px;
    border: 2px solid #d4e5fb;

    &--selected {
        border: 3px solid #529dff;
    }
}

.profile-method-card {
    width: 80px;
}